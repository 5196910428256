<template>
    <div class="faq-page overflow-hidden">
        <div class="faq-page__content">
            <div class="faq-page__header">
                <h1 class="pb-6 mb-6">Frequently Asked Questions</h1>
                <p class="mb-0">
                    You will find the most frequently asked questions here.
                    <br>
                    If you still have any questions regarding UNCO platform
                    <router-link :to="{name: 'ContactUs'}">Contact Us</router-link>
                </p>
            </div>

            <v-expansion-panels
                accordion
                flat
                multiple
                class="faq-page__accordion rounded-0">
                <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-close">
                        <span>What is UNCO?</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p class="mb-0">UNCO is a platform for jewelry designers who are looking for an easiest way to create pictures ready for your e-commerce platform, Marketing images or Social media posts.</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-close">
                        <span>What do I need to provide to create a 3D model?</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p>In order to create an accurate 3D model, send us a much information from sketches, URLs or CAD models as you can visualizing all the angles of your design. The better our 3D designers can understand the shape of your piece - the better we can portray it in 3D photorealistic renders.</p>

                        <p class="mb-0">Minimum required elements:</p>
                        <ul class="mb-4 pl-7 pl-sm-12">
                            <li>Minimum of 3 references images showing different angles (Front, Side, Perspective) Note that areas not visible in the references images will be improvised.</li>
                            <li>Dimensions</li>
                        </ul>

                        <p class="mb-0">Ideal scenario:</p>
                        <ul class="pl-7 pl-sm-12">
                            <li>CAD file</li>
                            <li>Real world photos</li>
                            <li>Asset name and SKU number (e.g. SKU123)</li>
                            <li>Dimensions</li>
                            <li>Written description</li>
                        </ul>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-close">
                        <span>How does UNCO work?</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p class="mb-0">You would first need to register and create a project of your desired design. Once you’ve done that, your project will be picked up by our highly skilled, jewelry focused, 3D designer. Once they have created the 3D model, you will be able to review your designs in our 3D viewer. Once you are happy with it, you can approve it and will be asked to specify what sort of renders do you require. After you submit them, our system will take of the rest and you will be able to download your final assets from your “Final Assets” page</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-close">
                        <span>How do I get started with UNCO?</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <ul>
                            <li>
                                1. You will first need to
                                <router-link :to="{name: 'registration'}">get registered</router-link>
                                on our platform
                            </li>
                            <li>2. Click “New Project” button at the top right corner.</li>
                            <li>3. Follow the instructions on your screen to next pages until you Complete your project request</li>
                        </ul>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-close">
                        <span>How will I get my renders from UNCO?</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p class="mb-0">All your renders will be kept in each projects “Final assets” page for you to download all of them in 1 click.</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-close">
                        <span>Who will manage my project?</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p class="mb-0">You will get a dedicated manager to help you out through your journey if any difficulties arise or you can issue a support ticket and our team will answer you as soon as possible.</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-close">
                        <span>How much does it cost?</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p class="mb-0">Depending on the complexity of your design it can cost as little as $50 for 4 angles including a 3D model. Extra renders or custom renders will cost extra and depend on the details.</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-close">
                        <span>Who will keep Intelectual Property of my design if a 3D model was made by UNCO?</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p class="mb-0">All IP rights will belong to you.</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script>
    export default {
        name: "faq"
    }
</script>

<style scoped lang="scss" src="./style.scss"></style>
